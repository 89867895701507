// 导出一个axios的实例  而且这个实例要有请求拦截器 响应拦截器
import axios from 'axios'
import router from '@/router'
import { Message, Loading } from 'element-ui'
import { color } from 'echarts'


let loadingInstance = null
const TimeOut = 3600 // 定义超时时间
const service = axios.create({
  baseURL: localStorage.getItem('testUrl'),
  // baseURL: 'https://s.szweishikeji.com',
  timeout: 20000
}) // 创建一个axios的实例
service.interceptors.request.use(config => {
   loadingInstance = Loading.service({ fullscreen: true,customClass: 'm-loading',text: '加载中...' });
      // config.headers['Authorization'] = `Bearer ${store.getters.token}`
      config.headers = {
        ...config.headers,
        'token': localStorage.getItem('token')
        // 'token': '4fd13c6b9cc15426'
      }
  return config
}, error => {
  loadingInstance && loadingInstance.close();
  return Promise.reject(error)
}) // 请求拦截器
service.interceptors.response.use(
  response => {
    loadingInstance && loadingInstance.close();
    if (response.data instanceof Blob) {
      return response.data // 返回了Blob对象
    }
    const { code, data, msg } = response.data
   
    if (code==1) {
      return data
    } else if (code==0) {
      Message.error(msg)
      return Promise.reject(new Error(msg))
    }else{
      return Promise.reject(new Error(msg))
    }
  },
  error => {
    loadingInstance && loadingInstance.close();
    console.log('error', error)
    return Promise.reject(error)
  }

) // 响应拦截器

// 更新baseURL
function updateAxiosBaseUrl(newBaseUrl) {
  service.defaults.baseURL = newBaseUrl;
  console.log('axios baseURL updated to:', service.defaults.baseURL);
}


export default {
  service,
  updateAxiosBaseUrl
} // 导出axios实例
