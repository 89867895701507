<template>
    <div>
        <div class="box" @click="downloadFormat=''">
        <!-- 课程说明书预览 -->
        <iframe 
            class="preview-iframe"
            :src="httpsUrl" 
            frameborder="0" 
            width="100%" 
            style="border: none;">
        </iframe>
        <div class="downLoad" @click="downF">下载</div>
        <van-popup ref="popup"  v-model="show" round position="bottom" :style="{ height: '289px' }" closeable>
          <div class="popup-content">
            <h3 class="popup-title">下载格式</h3>
              <div class="popup-select" tabIndex="1" @click.stop="downloadFormat='pdf'">PDF</div>
              <div class="popup-select" tabIndex="1" @click.stop="downloadFormat='docx'">Word<span>(下载后建议在PC端查看)</span></div>
            <div class="popup-confirm" @click.stop="() => { download(); afterDownload(); }">确认</div>
          </div>
        </van-popup>
</div>
    </div>
</template>

<script>
import wx from  "jweixin-module"
import { Toast } from 'vant';
import request from "../../utils/request";
import { Dialog } from 'vant';
  export default {
    name: 'downFile',
    data() {
      return {
        //下弹窗显示
        show: false,
        //下载格式
        downloadFormat:'',
        //课程详情ID
         courseId:this.$route.query.course_ids,
         //iframe地址
         httpsUrl:'',
         teacherName:this.$route.query.teacherName,
         courseName:this.$route.query.courseName,
      }
    },
    methods:{
      downF: function(){
        if(this.$route.query.token=='undefined'|| this.$route.query.token.trim()==''){
                console.log('请登录')
                Dialog.confirm({
                  title: '提示',
                  message: '请登录后再操作',
                  confirmButtonColor: "#FF8929"
                }).then(() => {
                    wx.miniProgram.navigateTo({
                        url:`/pages/common/login/login`
                     })
                  })
                  .catch(() => {
                    // on cancel
                    return
                  });
                  return
            }
        this.show=true
      },
        download: async function () {
         
            if(!this.downloadFormat){
              Toast.fail('请选择格式');
              return
            }
               
                    wx.miniProgram.navigateTo({
                url:`/pages_luo/download/download?format=${this.downloadFormat}&courseId=${this.courseId}&teacherName=${this.teacherName}&courseName=${this.courseName}`
            }) 
             
        },
        //获取iframe地址
        getIframeUrl(){
                this.httpsUrl = `${this.$route.query.url}/xapi/teacher.course/preview?course_ids=${this.$route.query.course_ids}`
            },
            //为了在跳转后，将之前选择的格式数据清除
            afterDownload(){
                if (typeof wx !== 'undefined') {
                    this.downloadFormat = ''
                }   
            }
    },
    created() {
      localStorage.setItem('token',this.$route.query.token) 
      localStorage.setItem('testUrl',this.$route.query.url) 
      //用于更新小程序带过来的baseurl ， 因为加载的时候request.js加载更快，在request.js里localStorage.getItem('testUrl')无法读取到值，故用此方式更新baseurl
      request.updateAxiosBaseUrl(localStorage.getItem('testUrl'))
      this.getIframeUrl()
    }
}
</script>

<style lang="less" scoped>
.box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .preview-iframe{
        width: 100vw;
        height: 88vh;
    }

    .downLoad{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 343px;
        height: 44px;
        background: #FF8929;
        border-radius: 12px;
        margin-top: 12px;
        margin-bottom: 34px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 16px;
        text-align: right;
        font-style: normal;
    }
    .popup-content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .popup-title{
            margin-top: 24px;
            margin-bottom: 18px;
            font-size: 16px;
        }
        .popup-select{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 335px;
            height: 38px;
            margin-top: 10px;
            background: #F6F6F6;
            border-radius: 2px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #282828;
            line-height: 12px;
            text-align: left;
            font-style: normal;
            padding: 23px 0px 21px;
        }
        .popup-select:focus{
            background: #FFF4EA;
            border-radius: 2px;
            border: 1px solid #FF8929;
            color: #FF8929;
        }
        .popup-confirm{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 343px;
            height: 44px;
            margin-top: 20px;
            margin-bottom: 34px;
            background: #FF8929;
            border-radius: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 16px;
            text-align: right;
            font-style: normal;
        }

    }
    :deep(.van-popup__close-icon){
        font-size: 20px;
        color: black;
    }
}

</style>