<template>
  <div class="join">
    <div>
    <!-- iframe部分 -->
    <!-- <iframe 
      class="H5"
      src="https://192.168.0.175:8020/#/prePdf" 
      frameborder="0" 
      width="100%" 
      height="5%"
      style="border: none;">
    </iframe> -->
      <pdf
			v-for="i in numPages"
			:key="i"
			:src="src"
			:page="i"
			class="pdf-set"
		></pdf>  
    <div style="height: 90px; background: #FFFFFF;"></div>
    <div class="button-group">
      <div class="button-group-sign-btn" @click="startContracts()">
        发起签署
      </div>
      <div class="button-group-complete-btn" @click="openPopup">
        完善信息
      </div>
    </div>
    <!-- 底部弹框 -->
    <van-popup ref="popup2"  v-model="show" round position="bottom" :style="{  padding:'24px 20px 17px 20px', }" closeable>
      <div class="popup-content">
          <div class="popup-title">完善信息</div> 
        <van-form ref="form"  >
          <van-field
            v-model="formData.partya_name"
            name="partya_name"
            label="甲方"
            placeholder="请输入甲方公司名称"
            required
            :rules="[{ required: true }]"
            style="padding:0px 0px 21px;"
          />
          <van-field
            v-model="formData.partya_contact_name"
            name="partya_contact_name"
            label="联系人"
            placeholder="请输入联系人姓名"
            required
            :rules="[{ required: true,  }]"
            style="padding: 23px 0px 21px;"
          />
          <van-field
            v-model="formData.partya_phone"
            name="partya_phone"
            label="联系方式"
            placeholder="请输入联系方式"
            required
            :rules="[{ required: true,  },{  pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码' }]"
            style="padding: 23px 0px 21px;"
          />
          <van-field
            v-model="formData.partya_addr"
            name="partya_addr"
            label="公司地址"
            placeholder="请输入公司地址"
            required
            :rules="[{ required: true,  }]"
            style="padding: 23px 0px 21px;"
          />
          <van-field
            v-model="formData.partya_lic"
            name="partya_lic"
            label="社会信用代码"
            placeholder="请输入统一社会信用代码"
            required
            :rules="[{ required: true,  }]"
            style="padding: 23px 0px 21px;"
          />
          <van-field
            v-model="formData.partya_email"
            name="partya_email"
            label="邮箱"
            placeholder="请输入邮箱"
            required
            :rules="[{ required: true,  },{
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: '请输入有效的邮箱地址'
        }]"
            style="padding: 23px 0px 21px;"
          />

          <van-field
            v-model="formData.partya_bank"
            name="partya_bank"
            label="开户行"
            placeholder="请输入开户行"
            required
            :rules="[{ required: true,  }]"
            style="padding: 23px 0px 21px;"
          />
          <van-field
            v-model="formData.partya_bank_account"
            name="partya_bank_account"
            label="银行账号"
            placeholder="请输入银行账号"
            required
            :rules="[{ required: true,  }]"
            style="padding: 23px 0px 21px;"
          />
  
          <div style="margin: 16px 0px; width: 100%; display: flex; justify-content: center; align-items: center; height: 44px;
                       background: #FF8929;
                       border-radius: 12px;
                       color: #FFFFFF;
                       /* margin:auto */
                       font-family: PingFangSC, PingFang SC;
                       font-weight: 500;
                       font-size: 16px;
                       color: #FFFFFF;
                       margin-bottom:17px;" @click.stop="onSubmit1">
    保存
    <!-- <van-button  style="width: 100% !important;
                       height: 44px;
                       background: #FF8929;
                       border-radius: 12px;
                       color: #FFFFFF;
                       /* margin:auto */
                       font-family: PingFangSC, PingFang SC;
                       font-weight: 500;
                       font-size: 16px;
                       color: #FFFFFF;
                       margin-bottom:0px;
                       border-color: #FF8929;
                       box-shadow: none !important;" block >保存</van-button> -->
  </div>
</van-form>
      </div>
    </van-popup>
  </div>
  </div>
</template>

<script>
import {
		Dialog
	} from 'vant';
  import { getContractInfo, saveContractInfo, contractPreBySchedule, startScheduleContract } from '@/api/mobileContract'
  import request from "../../utils/request";
  import pdf from 'vue-pdf'
  import { debounce } from 'lodash'
    export default {
      components: {
        pdf
      },
        data() {
            return {
                httpsUrl:'',
                show:false,
                formData:{
                  partya_bank_account: "",
                  partya_bank: "",
                  partya_contact_name: "",
                  partya_addr: "",
                  partya_name: "",
                  partya_phone: "",
                  partya_email:"",
                  partya_lic:"",
                  id: this.$route.query.id,
                },
                src:undefined,
                numPages:0,
                failmsg:false
              };
        },
        created() {
           localStorage.setItem('token',this.$route.query.token) 
           localStorage.setItem('testUrl',this.$route.query.url) 
           //用于更新小程序带过来的baseurl ， 因为加载的时候request.js加载更快，在request.js里localStorage.getItem('testUrl')无法读取到值，故用此方式更新baseurl
          request.updateAxiosBaseUrl(localStorage.getItem('testUrl'))
          // this.searchContractStatus() //TODO
          this.getIframeUrl()
  },

        methods: {
            back(){
              window.location.href = 'https://middle.kejiangtang.com?scene=videoCall';
            },
            //获取iframe地址
             getIframeUrl(){
              contractPreBySchedule(this.$route.query.id).then(res=>{
                  this.httpsUrl = res.url
                  this.src = pdf.createLoadingTask(this.httpsUrl);
                  this.src.promise.then(pdf => {
                      this.numPages = pdf.numPages;
                  })
                  console.log(this.httpsUrl,'res',this.src)
                })
                
            },
            //提交保存信息
             onSubmit1(){
              this.$refs.form?.validate().then(async()=>{
              // 验证通过
             await saveContractInfo(this.formData)
                  this.getIframeUrl()
                  this.show = false
              Dialog.alert({
                   message: '请确保填写信息正确',
                   confirmButtonColor: "#FF8929"
                 });
                  }).catch(()=>{
                  //验证失败
                  // Dialog.alert({
                  //                 message: '填写信息有误',
                  //                 confirmButtonColor: "#FF8929"
                  //               });
                  })
            },
            startContracts:debounce(function(){
              this.checkFormData()
              this.$refs.form.validate().then(async()=>{
              // 验证通过
              try {
                const res = await startScheduleContract(this.$route.query.id)
              window.location.href = res.partya_sign_url; 
              } catch (error) {
                
              }
             
             }).catch(()=>{
                  //验证失败
                  Dialog.alert({
                                  message: '填写信息有误',
                                  confirmButtonColor: "#FF8929"
                                });
                  })
              
              },300),
            //  startContracts(){
            //   this.checkFormData()
            //   this.$refs.form.validate().then(async()=>{
            //   // 验证通过
            //   try {
            //     const res = await startScheduleContract(this.$route.query.id)
            //   window.location.href = res.partya_sign_url; 
            //   } catch (error) {
                
            //   }
             
            //  }).catch(()=>{
            //       //验证失败
            //       Dialog.alert({
            //                       message: '填写信息有误',
            //                       confirmButtonColor: "#FF8929"
            //                     });
            //       })
              
            // },
            //获取信息
            async getInfo(){
              const res = await getContractInfo(this.$route.query.id)
              for(let i in this.formData){
                if(res[i]){
                  this.formData[i] = res[i]
                }
              }
            },
            //完善信息按钮
            openPopup(){
              this.getInfo()
              this.show = true
            },
             //检查表单值
             checkFormData() {
               const hasEmptyFields = Object.values(this.formData).some(value => value === "" || value === null);
               if (hasEmptyFields) {
                 Dialog.alert({
                   message: '请先完善信息',
                   confirmButtonColor: "#FF8929"
                 });
                 return false;
               }
               return true;
             },
         },
        
    };
</script>

<style scoped lang="less">
.join {
  // height: 100vh;
  width:100vw;
  background: #f7f8fa;
  display: flex;
  align-items: center;
  justify-content: center;
  position:relative;
  
.H5{
  width: 100vw;
  height: 85vh;
  position: absolute;
  top:0px;
  left: 0px;
}
.button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 12px 16px 34px;
  height: 90px;
  width:100vw;
  background: #FFFFFF;
  font-size: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  &-complete-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 214px;
    height: 44px;
    margin-left: 5px;
    background: #FF8929;
    border-radius: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 16px;
    font-style: normal;
  }
  &-sign-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 119px;
    height: 44px;
    margin-right: 5px;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #D0D0D0;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 16px;
    font-style: normal;
  }
}

.popup-content{
  display: flex;
  flex-direction: column;

  .popup-title{
    width: 64px;
    height: 16px;
    margin:auto;
    text-align: center;
    margin-bottom: 29px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    line-height: 16px;
    font-style: normal;
    white-space: nowrap;
}
}
}

::v-deep .van-cell--required {
   &:before{
     content: ''
   }
   .van-cell__title.van-field__label {
     span {
       position: relative;
       &:before {
         position: absolute;
         right: -8px;
         color: #ee0a24;
         font-size: 14px;
         content: '*';
       }
     }
   }
 }

 ::v-deep .van-cell::after {
  left: 0px;
  right: 0px;
 }

 ::v-deep .van-icon-cross:before{
  font-size: 18px;
    position: absolute;
    top: 0px;
    right: 3px;
    color: #333333 !important;
 }

 ::v-deep .van-field__label{
  margin-right: 30px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
 }

 ::v-deep .van-field__control::-webkit-input-placeholder {
  font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #999999;
}

::v-deep .van-cell{
  font-size: 16px;
}
::v-deep .van-field--error .van-field__control::-webkit-input-placeholder{
  color: #ee0a24 !important;
   
}
 .pdf-set{
  // text-align: center;
  // width:100%;

}

</style>
